import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    sidebarCollapse: PropTypes.bool.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
    onShow: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  hideSidebar = () => {
    this.props.onClick();
  };
  showSidebar = () => {
    this.props.onShow();
  };
  constructor(props) {
    super(props);
    this.state = {
      sidebarCollapse: props.sidebarCollapse
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.sidebarCollapse !== this.props.sidebarCollapse)
      this.setState({ sidebarCollapse: nextProps.sidebarCollapse });
  }
  render() {
    const { pathname } = this.props.location;
    return (
      <div className="sidebar__content">
        <div className="text-center mb-sm-5"></div>
        <ul className="sidebar__block">
          <SidebarLink
            key={"1"}
            route={{
              label: "Accueil",
              to: "/accueil",
              icon: "home",
              hasSubMenu: false,
              isActive: pathname === "/" || pathname === "/accueil",
              className: "sidebar__link"
            }}
            onClick={this.hideSidebar}
          />
          <SidebarCategory
            route={{
              label: "Mes utilisateurs",
              to: "/mes-utilisateurs",
              isActive:
                pathname === "/mes-utilisateurs" ||
                pathname === "/mes-demandes" ||
                pathname === "/ajout-utilisateur",
              icon: "team",
              className: "sidebar__link",
              index: ""
            }}
            sidebarCollapse={this.state.sidebarCollapse}>
            <SidebarLink
              key={"2"}
              route={{
                label: "Ajout un utilisateur",
                to: "/ajout-utilisateur",
                isActive: pathname === "/ajout-utilisateur",
                hasSubMenu: false,
                className: "sidebar__link1",
                icon: "inscris"
              }}
              onClick={this.hideSidebar}
            />
            <SidebarLink
              key={"3"}
              route={{
                label: "Mes demandes",
                className: "sidebar__link1",
                hasSubMenu: false,
                isActive: pathname === "/mes-demandes",
                to: "/mes-demandes",
                icon: "nb-demandes"
              }}
              onClick={this.hideSidebar}
            />
            <SidebarLink
              key={"4"}
              route={{
                label: "Mes utilisateurs",
                className: "sidebar__link1",
                hasSubMenu: false,
                isActive: pathname === "/mes-utilisateurs",
                to: "/mes-utilisateurs",
                icon: "users"
              }}
              onClick={this.hideSidebar}
            />
          </SidebarCategory>

          <SidebarLink
            key={"8"}
            route={{
              label: "Mon compte",
              to: "/compte",
              hasSubMenu: false,
              isActive: pathname === "/compte",
              className: "sidebar__link",
              icon: "my-account"
            }}
            onClick={this.hideSidebar}
          />
        </ul>
      </div>
    );
  }
}

export default withRouter(SidebarContent);
