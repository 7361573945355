import gql from "graphql-tag";

export const GET_USER_BY_ID = gql`
  query user($userId: ID) {
    user(userId: $userId) {
      id
      establishment {
        id
        profile {
          name
          photoUrl
          city
          address
          city
          country
        }
      }
      status
      createdAt
      online
      email

      learnedLanguage {
        name
        id
        flag
        chatFlag
      }
      spokenLanguage {
        name
        id
        flag
        chatFlag
      }
      email
      rating {
        qualityLevel
        sociabilityLevel
        learnedLevel
        learnedLevelRatingTimes
        ratingTimes
      }
      profile {
        lastName
        photoUrl
        firstName
        city
        address
      }
      createdAt
      lastLoggin
    }
  }
`;

export const GET_USERS_COUNT = gql`
  query usersCount(
    $search: String
    $filterLanguage: String
    $learnedLevel: Int
  ) {
    usersCount(
      search: $search
      filterLanguage: $filterLanguage
      learnedLevel: $learnedLevel
    )
  }
`;

export const GET_USERS_REQUESTS_COUNT = gql`
  query usersRequestsCount(
    $search: String
    $filterLanguage: String
    $learnedLevel: Int
  ) {
    usersRequestsCount(
      search: $search
      filterLanguage: $filterLanguage
      learnedLevel: $learnedLevel
    )
  }
`;

export const GET_USERS = gql`
  query users(
    $limit: Int!
    $skip: Int!
    $status: String
    $search: String
    $filterLanguage: String
    $learnedLevel: Int
  ) {
    users(
      limit: $limit
      skip: $skip
      status: $status
      search: $search
      filterLanguage: $filterLanguage
      learnedLevel: $learnedLevel
    ) {
      id
      establishment {
        id
        profile {
          name
          photoUrl
        }
      }
      status
      createdAt
      online
      email
      learnedLanguage {
        name
        id
      }
      spokenLanguage {
        name
        id
      }
      rating {
        qualityLevel
        sociabilityLevel
        learnedLevel
        learnedLevelRatingTimes
        ratingTimes
      }
      profile {
        lastName
        photoUrl
        firstName
        address
        city
        country
      }
      createdAt
      lastLoggin
    }
  }
`;

export const GET_USERS_REQUESTS = gql`
  query usersRequests(
    $limit: Int!
    $skip: Int!
    $search: String
    $filterLanguage: String
    $learnedLevel: Int
  ) {
    usersRequests(
      limit: $limit
      skip: $skip
      search: $search
      filterLanguage: $filterLanguage
      learnedLevel: $learnedLevel
    ) {
      id
      establishment {
        id
        profile {
          name
          photoUrl
        }
      }
      status
      createdAt
      online
      email
      learnedLanguage {
        name
        id
      }
      spokenLanguage {
        name
        id
      }
      rating {
        qualityLevel
        sociabilityLevel
        learnedLevel
        learnedLevelRatingTimes
        ratingTimes
      }
      profile {
        lastName
        photoUrl
        firstName
        address
      }
      createdAt
    }
  }
`;

export const GET_SETTINGS = gql`
  query getSettings {
    getSettings {
      rgpd
      legal
      tutoriel
    }
  }
`;
export const GET_STATS = gql`
  query usersStat {
    usersStat
  }
`;
