import React from "react";
import PropTypes from "prop-types";
import {
  CardBody,
  ListGroup,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import Button from "../../../shared/components/Button";
import AddressAutocomplete from "../../../shared/components/AddressAutocomplete";
import GraphQLResult from "../../../shared/components/GraphQLResult";
import { withRouter } from "react-router-dom";
import Alert from "../../../handler/utils/Alert";
import SelecLanguage from "../../../shared/components/SelecLanguage";
import Select from "../../../shared/components/Select";
import { GET_USERS, GET_STATS } from "../../../handler/queries";
import {
  BASE_LANGUAGE_URL,
  LEVELS_WITH_ID,
} from "../../../handler/utils/constants";
//const logo = `${process.env.PUBLIC_URL}/img/images/banner.png`;
const defaultErrors = {
  lastName: null,
  firstName: null,
  email: null,
  levelId: null,
  spokenLanguage: null,
  learnedLanguage: null,
  address: null,
  city: null,
  country: null,
};

class AddUpdateForm extends React.Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    handleChangeForm: PropTypes.func,
    refetch: PropTypes.func,
    className: PropTypes.string,
    title: PropTypes.string,
    error: PropTypes.string,
    handleChangeUpdate: PropTypes.func,
    addUserFromEtab: PropTypes.func,
    updateUserFromEtab: PropTypes.func,
    loading: PropTypes.bool,
    user: PropTypes.object,
    type: PropTypes.string.isRequired,
  };

  static defaultProps = {
    className: "Profil",
    title: "",
    user: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      modal: false,
      learnedLevel: 0,
      languages: [],

      lastName: props.user && props.user.profile && props.user.profile.lastName,
      firstName:
        props.user && props.user.profile && props.user.profile.firstName,
      email: props.user && props.user.email,
      spokenLanguage:
        props.user && props.user.spokenLanguage && props.user.spokenLanguage.id,
      learnedLanguage:
        props.user &&
        props.user.learnedLanguage &&
        props.user.learnedLanguage.id,
      learnedLevel:
        props.user && props.user.rating && props.user.rating.learnedLevel,

      errors: {
        ...defaultErrors,
      },
    };
    this.toggleFile = this.toggleFile.bind(this);
  }

  componentDidMount() {
    fetch(`${BASE_LANGUAGE_URL}/languages`).then((res) =>
      res.json().then((data) => this.setState({ languages: data.languages }))
    );
  }

  city =
    this.props.user && this.props.user.profile
      ? this.props.user.profile.city
      : null;
  country =
    this.props.user && this.props.user.profile
      ? this.props.user.profile.country
      : null;
  address =
    this.props.user && this.props.user.profile
      ? this.props.user.profile.address
      : null;

  onSelectLanguage = (value, name) => {
    if (name === "spokenLanguage") this.setState({ spokenLanguage: value });
  };
  onSelectLanguage2 = (value, name) => {
    if (name === "learnedLanguage") this.setState({ learnedLanguage: value });
  };
  onSelect = (value, name) => {
    if (name === "learnedLevel") this.setState({ learnedLevel: value });
  };
  onResponse = (cb) => {
    this.setState(
      {
        loading: false,
      },
      () => {
        cb();
      }
    );
  };

  setAddress = (object) => {
    if (object) {
      this.address = object.address;
      this.city = object.city;
      this.country = object.country;
    }
  };

  _handleSubmit = () => {
    if (this.props.type === "add") {
      this._handleAdd();
    }
    if (this.props.type === "update") {
      this._handleUpdate();
    }
  };

  _handleAdd = () => {
    if (!this.state.loading) {
      const lastName = this.state.lastName ? this.state.lastName.trim() : null;
      const firstName = this.state.firstName
        ? this.state.firstName.trim()
        : null;
      const email = this.state.email ? this.state.email.trim() : null;
      const spokenLanguage = this.state.spokenLanguage
        ? this.state.spokenLanguage.trim()
        : null;
      const learnedLanguage = this.state.learnedLanguage
        ? this.state.learnedLanguage.trim()
        : null;
      const learnedLevel = this.state.learnedLevel
        ? parseInt(this.state.learnedLevel)
        : 0;
      let address = this.address ? this.address : "";
      const city = this.city ? this.city : "";
      const country = this.country ? this.country : "";
      if (city && country) address = city + ", " + country;

      const errors = {
        ...defaultErrors,
      };

      if (address.length < 5)
        errors.address =
          "L'adresse est obligatoire et doit comporter au moins 5 caractères";
      if (errors.lastName || errors.firstName) {
        this.setState({ errors: { ...errors } });
        Alert.warning(
          "Merci de corriger les problèmes identifiés dans le formulaire"
        );
      } else {
        this.setState({ loading: true, errors });

        this.props
          .addUserFromEtab({
            variables: {
              input: {
                lastName,
                firstName,
                email,
                address,
                city,
                spokenLanguage,
                learnedLanguage,
                learnedLevel,
              },
              refetchQueries: [
                {
                  query: GET_USERS,
                  variables: {
                    state: "ALL",
                    search: null,
                    skip: 0,
                    limit: 10,
                  },
                },
                {
                  query: GET_STATS,
                },
              ],
            },
          })
          .then(() => {
            Alert.success("Utilisateur ajouté avec succès");
            this.props.history.push({
              pathname: "/mes-utilisateurs",
              state: { status: "ALL" },
            });
          })
          .catch((e) => {
            this.onResponse(() => {
              console.log(e);
            });
          });
      }
    }
  };

  _handleUpdate = () => {
    if (!this.state.loading) {
      const lastName = this.state.lastName ? this.state.lastName.trim() : null;
      const firstName = this.state.firstName
        ? this.state.firstName.trim()
        : null;
      const email = this.state.email ? this.state.email.trim() : null;
      const spokenLanguage = this.state.spokenLanguage
        ? this.state.spokenLanguage.trim()
        : null;
      const learnedLanguage = this.state.learnedLanguage
        ? this.state.learnedLanguage.trim()
        : null;
      const learnedLevel = this.state.learnedLevel
        ? parseInt(this.state.learnedLevel)
        : 0;
      let address = this.address
        ? this.address
        : this.props.user.profile.address;
      const city = this.city ? this.city : this.props.user.profile.city;

      const country = this.country
        ? this.country
        : this.props.user.profile.country;
      if (city && country) address = city + ", " + country;
      const errors = {
        ...defaultErrors,
      };
      if (!name || name.length < 5)
        errors.name =
          "Le titre est obligatoire et doit comporter au moins 5 caractères";

      if (address.length < 5)
        errors.address =
          "L'adresse est obligatoire et doit comporter au moins 5 caractères";
      if (errors.lastName || errors.firstName) {
        this.setState({ errors: { ...errors } });
        Alert.warning(
          "Merci de corriger les problèmes identifiés dans le formulaire"
        );
      } else {
        this.setState({ loading: true, errors });

        this.props
          .updateUserFromEtab({
            variables: {
              userId: this.props.user.id,
              input: {
                lastName,
                firstName,
                email,
                address,
                city,
                spokenLanguage,
                learnedLanguage,
                learnedLevel,
              },
              refetchQueries: [
                {
                  query: GET_USERS,
                  variables: {
                    state: "ALL",
                    search: null,
                    skip: 0,
                    limit: 10,
                  },
                },
                {
                  query: GET_STATS,
                },
              ],
            },
          })
          .then(({ data }) => {
            data &&
              data.updateUserFromEtab &&
              !data.updateUserFromEtab.success &&
              Alert.warning(data.updateUserFromEtab.message);
            data &&
              data.updateUserFromEtab &&
              data.updateUserFromEtab.success &&
              Alert.success(data.updateUserFromEtab.message);
            this.props.refetch();
            this.props.history.push({
              pathname: "/utilisateur/" + this.props.user.id,
              state: { status: "ALL" },
            });
          })
          .catch((e) => {
            this.onResponse(() => {
              console.log(e);
            });
          });
      }
    }
  };

  onOpenModal = () => {
    this.setState({ modalFile: !this.state.modalFile });
  };

  toggleFile() {
    this.setState({
      modalFile: !this.state.modalFile,
    });
  }

  onChangeInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { errors, email, firstName, lastName, languages } = this.state;
    const { error, loading, type } = this.props;

    return (
      <GraphQLResult loading={loading} error={error} emptyResult={false}>
        <CardBody>
          <ListGroup
            tag="div"
            className="Profil-group n"
            style={{ marginTop: this.props.type === "update" && "4em" }}>
            <Row style={{ marginBottom: 6 }}>
              <Col xs={12} md={4} lg={4} xl={4}>
                <FormGroup>
                  <Label className="Profil-group__label" for="name">
                    Prénom{" "}
                    {this.props.type === "add" && (
                      <span style={{ marginLeft: 5, color: "red" }}>*</span>
                    )}
                  </Label>
                  <Input
                    className="Profil-group__input"
                    type="text"
                    value={lastName}
                    onChange={this.onChangeInput}
                    name="firstName"
                    id="firstName"
                    placeholder="Entrez le prénom de l'utilisateur"
                    invalid={!!errors.lastName}
                  />
                  <FormFeedback>{errors.lastName}</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs={12} md={4} lg={4} xl={4}>
                <FormGroup>
                  <Label className="Profil-group__label" for="name">
                    Nom{" "}
                    {this.props.type === "add" && (
                      <span style={{ marginLeft: 5, color: "red" }}>*</span>
                    )}
                  </Label>
                  <Input
                    className="Profil-group__input"
                    type="text"
                    value={firstName}
                    onChange={this.onChangeInput}
                    name="lastName"
                    id="lastName"
                    placeholder="Entrez le nom de l'utilisateur"
                    invalid={!!errors.firstName}
                  />
                  <FormFeedback>{errors.firstName}</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs={12} md={4} lg={4} xl={4}>
                <FormGroup>
                  <Label className="Profil-group__label" for="name">
                    Email{" "}
                    {this.props.type === "add" && (
                      <span style={{ marginLeft: 5, color: "red" }}>*</span>
                    )}
                  </Label>
                  <Input
                    className="Profil-group__input"
                    type="text"
                    value={email}
                    onChange={this.onChangeInput}
                    name="email"
                    id="email"
                    placeholder="Entrez l'email de l'utilisateur"
                    invalid={!!errors.email}
                  />
                  <FormFeedback>{errors.email}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>

            <Row style={{ marginBottom: 6 }}>
              <Col xs={12} md={4} lg={4} xl={4}>
                <FormGroup>
                  <Label className="Profil-group__label" for="name">
                    Langue native{" "}
                    {this.props.type === "add" && (
                      <span style={{ marginLeft: 5, color: "red" }}>*</span>
                    )}
                  </Label>
                  <SelecLanguage
                    className="Profil-group__input"
                    onSelectLanguage={this.onSelectLanguage}
                    optionLabelDisabled={false}
                    defaultValue={
                      this.props.user &&
                      this.props.user.spokenLanguage &&
                      this.props.user.spokenLanguage.id
                    }
                    name={"spokenLanguage"}
                    items={languages}
                    error={errors.contract}
                  />
                  <FormFeedback>{errors.contract}</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs={12} md={4} lg={4} xl={4}>
                <FormGroup>
                  <Label className="Profil-group__label" for="name">
                    Langue apprise{" "}
                    {this.props.type === "add" && (
                      <span style={{ marginLeft: 5, color: "red" }}>*</span>
                    )}
                  </Label>
                  <SelecLanguage
                    className="Profil-group__input"
                    onSelectLanguage={this.onSelectLanguage2}
                    defaultValue={
                      this.props.user &&
                      this.props.user.learnedLanguage &&
                      this.props.user.learnedLanguage.id
                    }
                    optionLabelDisabled={false}
                    name={"learnedLanguage"}
                    items={languages}
                    error={errors.learnedLanguage}
                  />

                  <FormFeedback>{errors.learnedLanguage}</FormFeedback>
                </FormGroup>
              </Col>
              <Col xs={12} md={4} lg={4} xl={4}>
                <FormGroup>
                  <Label className="Profil-group__label" for="name">
                    Niveau{" "}
                    {this.props.type === "add" && (
                      <span style={{ marginLeft: 5, color: "red" }}>*</span>
                    )}
                  </Label>
                  <Select
                    className="Profil-group__input"
                    onSelect={this.onSelect}
                    defaultValue={
                      this.props.user &&
                      this.props.user.rating &&
                      this.props.user.rating.learnedLevel
                    }
                    optionLabelDisabled={false}
                    name={"learnedLevel"}
                    items={LEVELS_WITH_ID}
                    error={errors.learnedLevel}
                  />

                  <FormFeedback>{errors.learnedLevel}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ marginBottom: 6 }}>
              <Col xs={12} md={12} lg={12} xl={12}>
                <FormGroup>
                  <Label className="Profil-group__label" for="name">
                    Localisation{" "}
                    {this.props.type === "add" && (
                      <span style={{ marginLeft: 5, color: "red" }}>*</span>
                    )}
                  </Label>
                  <AddressAutocomplete
                    addressEntreprise={
                      this.props.user
                        ? {
                            address:
                              this.props.user.profile &&
                              this.props.user.profile.address,
                            city:
                              this.props.user.profile &&
                              this.props.user.profile.city,
                          }
                        : {}
                    }
                    setAddress={this.setAddress}
                    deafultAddress={this.address}
                  />

                  {errors.address && (
                    <span className="span-error">{errors.address}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </ListGroup>

          <div className="Profil-btns">
            <Button
              onClick={() => this.props.history.goBack()}
              className="Profil-btn__cancel"
              size="lg"
              text="Annuler"
              color="secondary"
              loading={this.state.loading}
            />

            <Button
              onClick={this._handleSubmit}
              className="Profil-btn__success"
              size="lg"
              text={this.props.type === "add" ? "SAUVEGARDER" : "MODIFIER"}
              color="primary"
              loading={this.state.loading}
            />
          </div>
          <div
            style={{
              justifyContent: "center",
              textAlign: "center",
              marginTop: 15,
            }}>
            {type === "add" && (
              <span
                style={{
                  color: "gray",
                  fontSize: "1.1em",
                }}>
                * Après avoir sauvegardé cet utilisateur, vous pourrez le
                retrouver dans la liste des utilisateurs
              </span>
            )}
          </div>
        </CardBody>
      </GraphQLResult>
    );
  }
}
const ADD_USER = gql`
  mutation addUserFromEtab($input: UserInputFromEtab!) {
    addUserFromEtab(input: $input) {
      success
      message
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUserFromEtab($userId: ID!, $input: UserInputFromEtab!) {
    updateUserFromEtab(userId: $userId, input: $input) {
      success
      message
    }
  }
`;

export default withRouter(
  compose(
    graphql(ADD_USER, {
      name: "addUserFromEtab",
    }),
    graphql(UPDATE_USER, {
      name: "updateUserFromEtab",
    })
  )(AddUpdateForm)
);
