import React from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { Container } from "reactstrap";
import { graphql } from "react-apollo";
import GraphQlResult from "../../shared/components/GraphQLResult";
import EntrepriseInfo from "./components/EntrepriseInfo";

class EntrepriseDetails extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    refetch: PropTypes.func,
    error: PropTypes.object,
    myEstablishment: PropTypes.object
  };

  static defaultProps = {
    loading: false,
    error: null,
    myEstablishment: null
  };

  render() {
    const { loading, error, myEstablishment } = this.props;
    return (
      <Container className="dashboard">
        <GraphQlResult
          error={error}
          loading={loading}
          emptyResult={!loading && !myEstablishment}>
          {myEstablishment && (
            <EntrepriseInfo user={myEstablishment} loading={loading} />
          )}
        </GraphQlResult>
      </Container>
    );
  }
}

const GET_MY_ESTABLISHMENT = gql`
  query myEstablishment {
    myEstablishment {
      id
      profile {
        name
        nameOfResponsible
        photoUrl
        country
        city
        address
      }
      email
      callPref
      mobile
      mobileVerified
      emailVerified
      status
      contract
      dateStartContract
      dateEndContract
      maxActifUserNumber
      createdAt
    }
  }
`;

export default graphql(GET_MY_ESTABLISHMENT, {
  options: () => ({
    fetchPolicy: "cache-and-network"
  }),
  props: ({ data: { loading, error, myEstablishment, refetch } }) => ({
    loading,
    error,
    myEstablishment,
    refetch
  })
})(EntrepriseDetails);
