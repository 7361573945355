import React from "react";
import { ScaleLoader } from "react-spinners";
import { Card } from "reactstrap";

const logo = `${process.env.PUBLIC_URL}/img/images/SB-2.png`;

const Fallback = () => (
  <div className="fallback-spinner">
    <Card className="text-center fallback-spinner-card">
      <img className="logo__img_2" src={logo} />
      <div className="p-sm-3">
        <ScaleLoader color="white" size={150} width={7} />
      </div>
    </Card>
  </div>
);

export default Fallback;
