import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Label } from "reactstrap";
import { GET_USERS, GET_STATS } from "../../../handler/queries";
import { SET_KEYVALUE } from "../../../handler/mutations.local";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import moment from "moment";
import SwitchTootgle from "../../../shared/components/Switch";
import Alert from "../../../handler/utils/Alert";
import { GET_KEYCONTACT } from "../../../handler/queries.local";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
class UserInfosTop extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    candidat: PropTypes.object,
    changeUserStatus: PropTypes.func,
    setKeyName: PropTypes.func,
    refetch: PropTypes.func,
    show: PropTypes.bool,
    KeyContract: PropTypes.string
  };
  static defaultProps = {
    candidat: {},
    fromApp: false,
    user: {},
    KeyContract: "PARTNERSHIP"
  };

  onStatusChange = () => {
    this.props
      .changeUserStatus({
        variables: {
          userId: this.props.user.id
        },
        refetchQueries: [
          {
            query: GET_USERS,
            variables: {
              state: "ALL",
              search: null,
              skip: 0,
              limit: 10
            }
          },
          {
            query: GET_STATS
          }
        ]
      })
      .then(rest => {
        this.props.refetch();
        if (
          rest.data &&
          rest.data.changeUserStatus &&
          rest.data.changeUserStatus.success
        )
          Alert.success(
            rest.data &&
              rest.data.changeUserStatus &&
              rest.data.changeUserStatus.message
          );
        else
          Alert.warning(
            rest.data &&
              rest.data.changeUserStatus &&
              rest.data.changeUserStatus.message
          );
      });
  };

  render() {
    const { user, KeyContract } = this.props;

    user &&
      user.profile &&
      user.profile.lastName &&
      this.props
        .setKeyName({
          variables: {
            KeyValue: `<a href='#/utilisateur/${user.id} '>${user.profile
              .lastName +
              " " +
              user.profile.firstName}</a> `
          }
        })
        .then(() => {});

    return (
      <React.Fragment>
        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody className="profile__card--calendar">
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                {KeyContract !== "PARTNERSHIP" && (
                  <SwitchTootgle
                    isOn={user.status === "ACTIVE"}
                    disabled={false}
                    onColor="rgb(46, 83, 152)"
                    handleToggle={() =>
                      confirmAlert({
                        title: "Changement de statut",
                        message: "Êtes-vous sûr de vouloir changer le statut?",
                        buttons: [
                          {
                            label: "Non",
                            onClick: () => {}
                          },
                          {
                            label: "Oui",
                            onClick: () => this.onStatusChange()
                          }
                        ]
                      })
                    }
                  />
                )}
              </div>
              <div
                style={{
                  alignItems: "center",
                  alignSelf: "center",
                  alignContent: "center",
                  textAlign: "center",
                  marginTop: 50
                }}>
                <div>
                  <img
                    src={
                      user && user.profile && user.profile.photoUrl
                        ? user.profile.photoUrl
                        : "https://f0.pngfuel.com/png/312/283/man-face-clip-art-png-clip-art-thumbnail.png"
                    }
                    style={{ width: 150, height: 150, borderRadius: 100 }}
                  />
                  {user.online && user.status === "ACTIVE" && (
                    <div className="user-online-2"></div>
                  )}
                </div>
                <div style={{ marginTop: 40 }}>
                  <Label
                    style={{
                      fontSize: "1.3rem",
                      fontWeight: 800,
                      marginBottom: 15
                    }}>
                    {user &&
                      user.profile &&
                      user.profile.lastName + " " + user.profile.firstName}
                  </Label>
                  <br />
                  <Label style={{ color: "gray", marginBottom: 5 }}>
                    {user && user.email}
                  </Label>
                  <br />
                  <Label style={{ color: "gray", marginBottom: 5 }}>
                    Date de création :{" "}
                    <span style={{ color: "#14aec5" }}>
                      {moment(user.createdAt).format("DD/MM/YYYY")}
                    </span>
                  </Label>
                  <br />
                  <Label style={{ color: "gray", marginBottom: 5 }}>
                    {user && user.profile && user.profile.city
                      ? user.profile.city
                      : user.profile.address}
                  </Label>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}
const UPDATE_OFFER_STATE = gql`
  mutation changeUserStatus($userId: ID!) {
    changeUserStatus(userId: $userId) {
      success
      message
    }
  }
`;

export default compose(
  graphql(UPDATE_OFFER_STATE, {
    name: "changeUserStatus"
  }),
  graphql(SET_KEYVALUE, {
    name: "setKeyName"
  }),
  graphql(GET_KEYCONTACT, {
    props: ({ data: { KeyContract } }) => ({
      KeyContract: KeyContract ? KeyContract.KeyContract : "PARTNERSHIP"
    })
  })
)(UserInfosTop);
