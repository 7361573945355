import React, { PureComponent } from "react";
import DownIcon from "mdi-react/ChevronDownIcon";
import { Collapse } from "reactstrap";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { SET_TOKEN, SET_KEYCONTRACT } from "../../../handler/mutations.local";
import TopbarMenuLink from "./TopbarMenuLink";
import { clientLogout } from "../../app/App";
const Ava =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQcz6CIVo0fLrlm9ssxHtKF4JEuLJBxlZXtIBePwbN3NTkkEVEp";

class TopbarProfile extends PureComponent {
  static propTypes = {
    setToken: PropTypes.func.isRequired,
    myEstablishment: PropTypes.object.isRequired,
    setKeyContract: PropTypes.func
  };

  static defaultProps = {
    myEstablishment: {
      profile: { name: "Mon compte", photoUrl: null }
    }
  };

  constructor() {
    super();
    this.state = {
      collapse: false
    };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  logout = () => {
    this.props
      .setToken({
        variables: {
          token: null
        }
      })
      .then(() => {
        clientLogout();
      });
  };
  render() {
    const { name, photoUrl } =
      this.props.myEstablishment && this.props.myEstablishment.profile;
    this.props.myEstablishment &&
      this.props.myEstablishment.contract &&
      this.props
        .setKeyContract({
          variables: {
            KeyContract: this.props.myEstablishment.contract
          }
        })
        .then(() => {});
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" onClick={this.toggle}>
          {photoUrl ? (
            <img src={photoUrl} alt="avatar" className="topbar__avatar-img" />
          ) : (
            <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          )}

          <p className="topbar__avatar-name">{name}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {this.state.collapse && (
          <button className="topbar__back" onClick={this.toggle} />
        )}
        <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
          <div className="topbar__menu" onClick={this.toggle}>
            <TopbarMenuLink title="Mon compte" icon="user" path="/compte" />
            <TopbarMenuLink
              title="Changer le mot de passe"
              icon="keyboard"
              path="/changer-mot-de-passe"
            />
            <div className="topbar__menu-divider" />
            <TopbarMenuLink
              title="Déconnexion"
              icon="exit"
              button={true}
              callback={this.logout}
            />
          </div>
        </Collapse>
      </div>
    );
  }
}
const GET_MY_ESTABLISHMENT = gql`
  query myEstablishment {
    myEstablishment {
      id
      contract
      profile {
        name
        photoUrl
      }
    }
  }
`;
export default compose(
  graphql(GET_MY_ESTABLISHMENT, {
    options: () => ({
      fetchPolicy: "cache-and-network"
    }),
    props: ({ data: { loading, error, myEstablishment } }) => ({
      loading,
      error,
      myEstablishment
    })
  }),
  graphql(SET_TOKEN, {
    name: "setToken"
  }),
  graphql(SET_KEYCONTRACT, {
    name: "setKeyContract"
  })
)(TopbarProfile);
