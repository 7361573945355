import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
//import Topbar from "./topbar/Topbar";
import Sidebar from "./sidebar/Sidebar";
import PropTypes from "prop-types";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import TopbarWithNavigation from "./topbar_with_navigation/TopbarWithNavigation";
class Layout extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    usersStat: PropTypes.array
  };
  static defaultProps = {
    usersStat: []
  };
  constructor(props) {
    super(props);
    let { pathname } = props.location;
    this.state = {
      sidebarShow: false,
      sidebarCollapse:
        pathname === "/" || pathname === "/accueil" ? false : true
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.location &&
      nextProps.location.pathname &&
      (nextProps.location.pathname === "/" ||
        nextProps.location.pathname === "/accueil")
    )
      this.setState({ sidebarCollapse: false });
    else this.setState({ sidebarCollapse: true });
  }
  changeSidebarVisibility = () => {
    this.setState({ sidebarCollapse: !this.state.sidebarCollapse });
  };

  changeMobileSidebarVisibility = () => {
    this.setState({ sidebarShow: !this.state.sidebarShow });
  };
  changeSidebarVisibilityTrue = () => {
    this.setState({ sidebarCollapse: false });
  };
  changeSidebarVisibilityFalse = () => {
    this.setState({ sidebarCollapse: true });
  };
  render() {
    const { sidebarShow, sidebarCollapse } = this.state;
    const { usersStat } = this.props;
    const layoutClass = classNames({
      layout: true,
      "layout--collapse": sidebarCollapse
    });

    return (
      <div className={layoutClass}>
        <TopbarWithNavigation
          pathname={this.props.location.pathname}
          pending={usersStat.length > 0 ? usersStat[2] : 0}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
        />
        <Sidebar
          changeSidebarVisibilityTrue={this.changeSidebarVisibilityTrue}
          sidebarShow={sidebarShow}
          sidebarCollapse={sidebarCollapse}
          changeSidebarVisibility={this.changeSidebarVisibility}
          changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
        />
      </div>
    );
  }
}

const GET_STATS = gql`
  query usersStat {
    usersStat
  }
`;

export default withRouter(
  compose(
    graphql(GET_STATS, {
      options: () => ({
        fetchPolicy: "network-only"
      }),
      props: ({ data: { loading, error, usersStat, refetch } }) => ({
        loading,
        error,
        usersStat,
        refetch
      })
    })
  )(Layout)
);
