import React from "react";
import PropTypes from "prop-types";
import { Redirect, withRouter, Link } from "react-router-dom";
import { graphql, compose } from "react-apollo";
import Layout from "../layout/index";
import { IS_LOGGED_IN, GET_KEYVALUE } from "../../handler/queries.local";
import Footer from "../layout/footer/Footer";
import Parser from "html-react-parser";
import Icon from "../../shared/components/Icon";
const routes = [
  {
    path: "/compte",
    parentLabel: "Mes utilisateurs",
    hasParent: false,
    parentPath: "/mes-utilisateurs",
    label: "Mon compte",
    icon: "my-account"
  },
  {
    path: "/ajout-utilisateur",
    parentLabel: "Mes utilisateurs",
    hasParent: true,
    parentPath: "/mes-utilisateurs",
    label: "Ajout d'un utilisateur",
    icon: "inscris"
  },
  {
    path: "/modifier-utilisateur",
    parentLabel: "Mes utilisateurs",
    hasParent: true,
    parentPath: "/mes-utilisateurs",
    label: "Modification d'un utilisateur",
    icon: "my-account"
  },
  {
    path: "/mes-demandes",
    parentLabel: "Mes utilisateurs",
    hasParent: false,
    parentPath: "/mes-utilisateurs",
    label: "Demandes d'inscriptions",
    icon: "settings"
  },

  {
    path: "/parametres",
    parentLabel: "Mes utilisateurs",
    hasParent: false,
    parentPath: "/mes-utilisateurs",
    label: "Paramètres",
    icon: "settings"
  },
  {
    path: "/changer-mot-de-passe",
    parentLabel: "Mon compte",
    hasParent: true,
    showPlus: false,
    parentPath: "/compte",
    label: "Changer mon mot de passe",
    icon: "my-account"
  },
  {
    path: "/mes-utilisateurs",
    label: "Mes utilisateurs",
    parentLabel: "Mes utilisateurs",
    hasParent: false,
    parentPath: "/mes-utilisateurs",
    icon: "my-account"
  },
  {
    path: "/utilisateur/",
    parentLabel: "Mes utilisateurs",
    hasParent: true,
    showPlus: true,
    parentPath: "/mes-utilisateurs",
    label: "Aperçu de : ",
    icon: "my-account"
  },

  {
    path: "/mon-utilisateur/",
    parentLabel: "Mes utilisateurs",
    hasParent: true,
    showPlus: true,
    parentPath: "/mes-utilisateurs",
    label: "Détail de : ",
    icon: "my-account"
  },

  {
    path: "/tutoriel",
    parentLabel: "Mes utilisateurs",
    hasParent: false,
    parentPath: "/mes-utilisateurs",
    label: "Tutoriel",
    icon: "help"
  },
  {
    path: "/rgpd",
    label: "RGPD",
    parentLabel: "Mes utilisateurs",
    hasParent: false,
    parentPath: "/mes-utilisateurs",
    icon: "my-account"
  },
  {
    path: "/mention-legale",
    parentLabel: "Mentions légales",
    hasParent: false,
    parentPath: "/mes-utilisateurs",
    label: "Mentions légales",
    icon: "my-account"
  }
];
class PrivateRoute extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    isLoggedIn: PropTypes.bool,
    KeyValue: PropTypes.string,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  render() {
    const { children, isLoggedIn, KeyValue } = this.props;

    const { pathname } = this.props.location;

    return isLoggedIn === false ? (
      <Redirect to="/connexion" />
    ) : (
      <div id="app">
        <Layout />
        <div className="container__wrap">
          {pathname !== "/" && pathname !== "/accueil" && (
            <div className="pageTitle">
              <Icon className="pageTitle-icon" name="home" />
              <Link to="/accueil">Accueil</Link> /{" "}
              {routes.map(route =>
                pathname.includes(route.path) ? (
                  route.hasParent ? (
                    <React.Fragment key={Math.random()}>
                      <Link to={route.parentPath}>{route.parentLabel}</Link> /{" "}
                      {route.label}{" "}
                      {KeyValue && route.showPlus ? Parser(KeyValue) : null}
                    </React.Fragment>
                  ) : (
                    route.label
                  )
                ) : null
              )}
            </div>
          )}
          {children}
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(
  compose(
    graphql(IS_LOGGED_IN, {
      props: ({ data: { isLoggedIn } }) => ({
        isLoggedIn: isLoggedIn ? isLoggedIn.isLoggedIn : false
      })
    }),
    graphql(GET_KEYVALUE, {
      props: ({ data: { KeyValue } }) => ({
        KeyValue: KeyValue ? KeyValue.KeyValue : null
      })
    })
  )(PrivateRoute)
);
