export const PROJECT_NAME = "Welango";
export const HOME = "Accueil";
export const PROFILE = "Mon compte";
export const MYCV = "Mes CV";
export const SHARE_CV = "CV Partagés";
export const MY_OFFERS = "Mes utilisateurs";
export const SETTINGS = "Paramètres";
export const PASSWORD = "Modification du mot de pesse";
export const ADD_OFFER = "CREATION D'UNE OFFRE";
const LOGIN = "Connexion";

export const titles = {
  LOGIN
};

export const languagesConst = [
  {
    id: 1,
    value: "fr",
    label: "Français"
  },
  {
    id: 2,
    value: "en",
    label: "Anglais"
  },
  {
    id: 3,
    value: "sp",
    label: "Espagnol"
  },
  {
    id: 4,
    value: "all",
    label: "Toutes les langues"
  }
];

export const statusConst = [
  {
    id: 1,
    value: "ALL",
    label: "Tous les statuts"
  },
  {
    id: 2,
    value: "ACTIVE",
    label: "Mes utlisateurs actifs"
  },
  {
    id: 3,
    value: "INACTIVE",
    label: "Mes utlisateurs inactifs"
  }
];

export const sortApplicationsConst = [
  {
    id: 1,
    value: "recent",
    label: "Date de candidature"
  },
  {
    id: 2,
    value: "experience",
    label: "Expérience"
  },
  {
    id: 3,
    value: "disponibility",
    label: "Disponibilité"
  }
];

export const sortOffersConst = [
  {
    id: 1,
    value: "name;1",
    label: "A-Z"
  },
  {
    id: 2,
    value: "name;-1",
    label: "Z-A"
  },
  {
    id: 3,
    value: "createdAt;-1",
    label: "Les plus récentes"
  },
  {
    id: 4,
    value: "createdAt;1",
    label: "Les plus anciennes"
  }
];
export const LEVELS_WITH_ID = [
  {
    id: 0,
    label: "A1"
  },
  {
    id: 1,
    label: "A2"
  },
  {
    id: 2,
    label: "B1"
  },
  {
    id: 3,
    label: "B2"
  },
  {
    id: 4,
    label: "C1"
  }
];
export const LEVELS = ["A1", "A2", "B1", "B2", "C1"];
export const QUALITY = ["N/A", "Mauvais", "Intermédiaire", "Bien", "Super"];
export const SOCIABILITY = ["N/A", "Mauvais", "Intermédiaire", "Bien", "Super"];

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const NAME_REGEX = /^[a-zA-Z ]{2,50}$/;
export const REGEX_MOBILE = /^[0-9]{9,13}$/;
export const REGEX_WEBSITE = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

export const ALERT_WARNING = {
  type: "warning",
  icon: "fa fa-exclamation-triangle"
};

export const ALERT_ERROR = {
  type: "error",
  icon: "fa fa-exclamation-circle"
};

export const ALERT_INFO = {
  type: "info",
  icon: "fa fa-info"
};

export const ALERT_SUCCESS = {
  type: "success",
  icon: "fa fa-check"
};

export const ALERT_DEFAULT_CONFIG = {
  manyInstances: false,
  disableAutoClose: false,
  autoClose: 2500
};

const isLocal = false;

const PROTOCOL = isLocal ? "http://" : "http://";
const WS_PROTOCOL = isLocal ? "ws://" : "ws://";
const HOST = isLocal ? "localhost:4003" : "apiwelango.toolynk.fr:4003";
const HOST_LANGUAGE = isLocal ? "localhost:4001" : "apiwelango.toolynk.fr:4001";
//const PORT = "6500";
const WS_ENDPOINT = "/subscriptions";

export const BASE_URL = `${PROTOCOL}${HOST}`;
export const BASE_LANGUAGE_URL = `${PROTOCOL}${HOST_LANGUAGE}`;
export const WS_URL = `${WS_PROTOCOL}${HOST}${WS_ENDPOINT}`;
