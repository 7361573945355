import React from "react";
import PropTypes from "prop-types";
import { Container, Button } from "reactstrap";
import ListeUsers from "./components/ListeUsers";
import { withRouter } from "react-router-dom";
import AddIcon from "mdi-react/UserAddIcon";
import { graphql } from "react-apollo";
import { GET_KEYCONTACT } from "../../handler/queries.local";
class MyUsers extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    KeyContract: PropTypes.string
  };

  static defaultProps = {
    loading: false,
    error: null,
    KeyContract: "PARTNERSHIP"
  };
  render() {
    const { KeyContract } = this.props;
    return (
      <Container className="dashboard">
        <ListeUsers status={"ALL"} />
        {KeyContract !== "PARTNERSHIP" && (
          <div className="Button-add__div" title="Ajouter un utilisateur">
            <Button
              className="Button-add__btn"
              onClick={() => this.props.history.push("/ajout-utilisateur")}>
              <AddIcon className="Button-add__btn-icon" />
            </Button>
          </div>
        )}
      </Container>
    );
  }
}
export default withRouter(
  graphql(GET_KEYCONTACT, {
    props: ({ data: { KeyContract } }) => ({
      KeyContract: KeyContract ? KeyContract.KeyContract : "PARTNERSHIP"
    })
  })(MyUsers)
);
