/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from "react";
import { Card, CardBody } from "reactstrap";
import PropTypes from "prop-types";
import Icon from "../../../shared/components/Icon";
import Rating from "../../../shared/components/Rating";

class InfosCard extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    icon: PropTypes.string,
    isImage: PropTypes.bool,
    isRating: PropTypes.bool,
    rating: PropTypes.number,
    labelClassName: PropTypes.string,
    iconContainerClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    valueClassName: PropTypes.string
  };

  static defaultProps = {
    labelClassName: "",
    iconClassName: "",
    valueClassName: "",
    isImage: false,
    isRating: false,
    rating: 0
  };

  render() {
    const {
      label,
      value,
      icon,
      isImage,
      isRating,
      rating,
      iconClassName
    } = this.props;
    return (
      <Card
        style={{
          paddingBottom: 0,
          marginBottom: 10,
          height: 100
        }}>
        <CardBody style={{ display: "flex" }}>
          <div
            style={{
              flex: "1 1",
              fontWeight: 650
            }}>
            <div
              style={{ color: "#17cde8", fontSize: "1.1rem", fontWeight: 800 }}>
              {value}
            </div>
            <div style={{ fontSize: "0.9rem", color: "#949494" }}>{label}</div>
          </div>
          <div style={{ borderRadius: "50%", color: "#17cde8" }}>
            {isRating ? (
              <Rating rating={rating} />
            ) : isImage ? (
              <img src={icon} style={{ width: 65 }} />
            ) : (
              <Icon
                name={icon}
                className={`statistics-card__icon ${iconClassName}`.trim()}
              />
            )}
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default InfosCard;
