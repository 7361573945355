/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from "react";
import { Card, CardBody, Col } from "reactstrap";
import PropTypes from "prop-types";
import Icon from "../../../shared/components/Icon";
import { Link } from "react-router-dom";

class StatisticsCard extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.number,
    icon: PropTypes.string,
    link: PropTypes.string,
    labelClassName: PropTypes.string,
    iconContainerClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    valueClassName: PropTypes.string
  };

  static defaultProps = {
    labelClassName: "",
    iconClassName: "",
    valueClassName: "",
    link: null
  };

  render() {
    const { label, value, icon, link, iconClassName } = this.props;
    return (
      <Col md={12} xl={12} lg={12} xs={12} style={{ marginBottom: 20 }}>
        <Card
          className={`${iconClassName}-bottom`.trim()}
          style={{ paddingBottom: 0 }}>
          {link ? (
            <CardBody className="dashboard__card-widget nono_pad">
              <Link className="filesLink" to={link}>
                <div className="statistics-card__container">
                  <div
                    className="round font-size-medium mt-sm-3 btn btn-primary btn-block btn_of"
                    style={{ width: "90%", fontWeight: 600 }}>
                    {label}
                  </div>
                </div>
              </Link>
            </CardBody>
          ) : (
            <CardBody className="dashboard__card-widget">
              <div className="statistics-card__container">
                <div
                  className="statistics-card__value"
                  style={{ textTransform: "none", fontSize: 22 }}>
                  {value}
                </div>
                <div className="statistics-card__label">{label}</div>
              </div>
              <div className={"statistics-card__icon-container icon-container"}>
                <Icon
                  name={icon}
                  className={`statistics-card__icon ${iconClassName}`.trim()}
                />
              </div>
            </CardBody>
          )}
        </Card>
      </Col>
    );
  }
}

export default StatisticsCard;
