import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import { GET_KEYCONTACT } from "../../../../handler/queries.local";

class TopbarNav extends React.PureComponent {
  static propTypes = {
    KeyContract: PropTypes.string,
    pathname: PropTypes.string,
    pending: PropTypes.number
  };
  static defaultProps = {
    KeyContract: "PARTNERSHIP",
    pathname: "/",
    pending: 0
  };

  render() {
    const { KeyContract, pathname, pending } = this.props;

    return (
      <nav className="topbar__nav">
        <Link
          className={
            pathname === "/mes-utilisateurs"
              ? "topbar__nav-link_active"
              : "topbar__nav-link"
          }
          to="/mes-utilisateurs">
          Mes utilisateurs
        </Link>
        {KeyContract !== "PARTNERSHIP" && (
          <Link
            className={
              pathname === "/mes-demandes"
                ? "topbar__nav-link_active"
                : "topbar__nav-link"
            }
            to="/mes-demandes">
            Mes demandes d'inscriptions
            <span
              className={
                pathname === "/mes-demandes"
                  ? "topbar__nav-link_active_span"
                  : "topbar__nav-link_span"
              }>
              {pending}
            </span>
          </Link>
        )}
        {/* <Link
          className={
            pathname === "/compte"
              ? "topbar__nav-link_active"
              : "topbar__nav-link"
          }
          to="/compte">
          Mon compte
        </Link>
          <Link className="topbar__nav-link" to="/parametres">
      Paramètres
    </Link> */}
      </nav>
    );
  }
}

export default graphql(GET_KEYCONTACT, {
  props: ({ data: { KeyContract } }) => ({
    KeyContract: KeyContract ? KeyContract.KeyContract : "PARTNERSHIP"
  })
})(TopbarNav);
