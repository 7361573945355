import React from "react";
import PropTypes from "prop-types";
import { Col, Label } from "reactstrap";
import { Pie } from "react-chartjs-2";
import { LEVELS, languagesConst } from "../../../handler/utils/constants";

class StatisticsPie extends React.PureComponent {
  static propTypes = {
    stats: PropTypes.array,
    language: PropTypes.string
  };

  static defaultProps = {
    stats: [],
    language: "all"
  };

  render() {
    const { language, stats } = this.props;
    const PieData = {
      labels: LEVELS,
      datasets: [
        {
          labels: LEVELS,
          data: stats,
          backgroundColor: [
            "#36A2EB",
            "#FFCE56",
            "#5de66a",
            "#27498c",
            "#4c8a37"
          ],
          hoverBackgroundColor: [
            "#36A2EB",
            "#FFCE56",
            "#5de66a",
            "#27498c",
            "#4c8a37"
          ]
        }
      ]
    };

    return (
      <Col xs={12} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
        <Pie data={PieData} />
        <Label style={{ textAlign: "center", marginTop: 20 }}>
          {language !== "all"
            ? languagesConst.filter(exp => exp.value === language)[0].label
            : "Toutes les langues"}
        </Label>
      </Col>
    );
  }
}

export default StatisticsPie;
