import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Query, graphql } from "react-apollo";
import Pagination from "react-js-pagination";
import SortAscendingIcon from "mdi-react/SortAscendingIcon";
import SortDescendingIcon from "mdi-react/SortDescendingIcon";
import SortIcon from "mdi-react/SortIcon";
import HeaderListesUsers from "./HeaderListesUsers";
import UserItem from "./UserItem";
import { GET_USERS_COUNT, GET_USERS } from "../../../handler/queries";
import { GET_KEYCONTACT } from "../../../handler/queries.local";
import { CardBody, Row, Col } from "reactstrap";
const image = `${process.env.PUBLIC_URL}/img/images/not-found.jpg`;
import { Link } from "react-router-dom";
class ListeUsers extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.object,
    status: PropTypes.string,
    data: PropTypes.object,
    users: PropTypes.func,
    KeyContract: PropTypes.string
  };

  static defaultProps = {
    loading: false,
    error: null,
    data: {},
    KeyContract: "PARTNERSHIP"
  };

  constructor(props) {
    super(props);

    this.state = {
      status: props.status,
      sort: "recent",
      direction: 1,
      activePage: 1,
      search: null,
      filterLanguage: null,
      sortName: 0,
      sortDate: 0,
      sortConne: 0,
      learnedLevel: -1
    };
  }

  handlePageChange = pageNumber => {
    this.setState({
      activePage: pageNumber,
      skip: pageNumber * 2
    });
  };

  getStatus = value => {
    this.setState({ status: value });
  };
  getFilterLanguage = value => {
    this.setState({ filterLanguage: value });
  };
  getLearnedLevel = value => {
    this.setState({ learnedLevel: parseInt(value) });
  };
  getSort = (field, direction) => {
    this.setState({ field, direction });
  };

  onChangeSearch = value => {
    this.setState({ search: value });
  };
  onSortName = () =>
    this.setState({
      sortName: this.state.sortName === 1 ? -1 : 1,
      sortDate: 0,
      sortConne: 0
    });
  onSortDate = () =>
    this.setState({
      sortDate: this.state.sortDate === 1 ? -1 : 1,
      sortName: 0,
      sortConne: 0
    });
  onSortConnec = () =>
    this.setState({
      sortConne: this.state.sortConne === 1 ? -1 : 1,
      sortName: 0,
      sortDate: 0
    });
  render() {
    const {
      activePage,
      status,
      search,
      sortName,
      sortDate,
      sortConne,
      filterLanguage,
      learnedLevel
    } = this.state;
    const { KeyContract } = this.props;

    return (
      <CardBody>
        <HeaderListesUsers
          getSort={this.getSort}
          getStatus={this.getStatus}
          onChangeSearch={this.onChangeSearch}
          getFilterLanguage={this.getFilterLanguage}
          getLearnedLevel={this.getLearnedLevel}
          status={status}
          search={search}
        />
        <Query
          query={GET_USERS_COUNT}
          fetchPolicy="network-only"
          variables={{
            search,
            filterLanguage,
            learnedLevel
          }}>
          {({ data: { usersCount }, loading, error }) => {
            if (loading) return <div />;
            else if (error) return <p>ERROR</p>;

            return (
              <Query
                query={GET_USERS}
                fetchPolicy="network-only"
                variables={{
                  limit: 10,
                  skip: (activePage - 1) * 10,
                  status,
                  search,
                  filterLanguage,
                  learnedLevel
                }}>
                {({ data: { users }, loading, error, refetch }) => {
                  if (loading) return <div />;
                  else if (error) return <p>ERROR</p>;

                  return (
                    <Fragment>
                      {users && users.length > 0 && (
                        <Row
                          className="OfferItem"
                          style={{ marginLeft: 0, marginRight: 0 }}>
                          <Col
                            xs={12}
                            md={3}
                            lg={3}
                            xl={3}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingLeft: "3%"
                            }}>
                            <div className="OfferItem-title">Utilisateur</div>
                            <span
                              onClick={this.onSortName}
                              style={{ marginLeft: 10, cursor: "pointer" }}>
                              {sortName === 0 ? (
                                <SortIcon />
                              ) : sortName === -1 ? (
                                <SortAscendingIcon />
                              ) : (
                                <SortDescendingIcon />
                              )}
                            </span>
                          </Col>
                          <Col
                            xs={12}
                            md={KeyContract !== "PARTNERSHIP" ? 2 : 3}
                            lg={KeyContract !== "PARTNERSHIP" ? 2 : 3}
                            xl={KeyContract !== "PARTNERSHIP" ? 2 : 3}
                            style={{ display: "flex", alignItems: "center" }}>
                            <div className="OfferItem-title">
                              Langue apprise
                            </div>
                          </Col>

                          <Col
                            xs={12}
                            md={KeyContract !== "PARTNERSHIP" ? 2 : 3}
                            lg={KeyContract !== "PARTNERSHIP" ? 2 : 3}
                            xl={KeyContract !== "PARTNERSHIP" ? 2 : 3}
                            style={{ display: "flex", alignItems: "center" }}>
                            <div className="OfferItem-title">Date d'ajout</div>
                            <span
                              onClick={this.onSortDate}
                              style={{ marginLeft: 10, cursor: "pointer" }}>
                              {sortDate === 0 ? (
                                <SortIcon />
                              ) : sortDate === -1 ? (
                                <SortAscendingIcon />
                              ) : (
                                <SortDescendingIcon />
                              )}
                            </span>
                          </Col>

                          <Col
                            xs={12}
                            md={KeyContract !== "PARTNERSHIP" ? 2 : 3}
                            lg={KeyContract !== "PARTNERSHIP" ? 2 : 3}
                            xl={KeyContract !== "PARTNERSHIP" ? 2 : 3}
                            style={{ display: "flex", alignItems: "center" }}>
                            <div className="OfferItem-title">
                              Dernière connexion
                            </div>
                            <span
                              onClick={this.onSortConnec}
                              style={{ marginLeft: 10, cursor: "pointer" }}>
                              {sortConne === 0 ? (
                                <SortIcon />
                              ) : sortConne === -1 ? (
                                <SortAscendingIcon />
                              ) : (
                                <SortDescendingIcon />
                              )}
                            </span>
                          </Col>

                          {KeyContract !== "PARTNERSHIP" && (
                            <Col xs={12} md={3} lg={3} xl={3}></Col>
                          )}
                        </Row>
                      )}
                      {users && users.length > 0 ? (
                        users
                          .sort(
                            sortName !== 0
                              ? function(a, b) {
                                  if (
                                    a.profile.lastName.toLowerCase() <
                                    b.profile.lastName.toLowerCase()
                                  ) {
                                    return -1 * sortName;
                                  }
                                  if (
                                    a.profile.lastName.toLowerCase() >
                                    b.profile.lastName.toLowerCase()
                                  ) {
                                    return 1 * sortName;
                                  }
                                  return 0;
                                }
                              : sortDate !== 0
                              ? function(a, b) {
                                  if (a.createdAt < b.createdAt) {
                                    return -1 * sortDate;
                                  }
                                  if (a.createdAt > b.createdAt) {
                                    return 1 * sortDate;
                                  }
                                  return 0;
                                }
                              : sortConne !== 0
                              ? function(a, b) {
                                  if (a.lastLoggin < b.lastLoggin) {
                                    return -1 * sortConne;
                                  }
                                  if (a.lastLoggin > b.lastLoggin) {
                                    return 1 * sortConne;
                                  }
                                  return 0;
                                }
                              : function() {
                                  return 0;
                                }
                          )
                          .map(user => {
                            return (
                              <UserItem
                                KeyContract={KeyContract}
                                key={user.id}
                                user={user}
                                state={status}
                                search={search}
                                skip={(activePage - 1) * 4}
                                limit={4}
                                refetch={refetch}
                              />
                            );
                          })
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            alignSelf: "center",
                            alignItems: "center"
                          }}>
                          <img
                            src={image}
                            style={{ minWidth: 300, width: "25%" }}
                          />
                          {KeyContract !== "PARTNERSHIP" ? (
                            <span>
                              Vous n'avez pas encore d'utilisateurs dans cette
                              liste
                              <br />
                              Vous pouvez commencer par{" "}
                              <Link to="/ajout-utilisateur">
                                créer un utilisateur
                              </Link>
                              <br />
                              ou accepter{" "}
                              <Link to="/mes-demandes">
                                les demandes d'inscription
                              </Link>
                            </span>
                          ) : (
                            <span>
                              Vous n'avez pas encore d'utilisateurs dans cette
                              liste
                            </span>
                          )}
                        </div>
                      )}
                      {users && users.length > 0 && (
                        <div style={{ textAlign: "center" }}>
                          <Pagination
                            prevPageText="Précédente"
                            nextPageText="Suivante"
                            firstPageText="Première"
                            lastPageText="Dernière"
                            activePage={activePage}
                            itemsCountPerPage={10}
                            innerClass={"pagination"}
                            totalItemsCount={usersCount ? usersCount : 0}
                            pageRangeDisplayed={10}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      )}
                    </Fragment>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </CardBody>
    );
  }
}

export default graphql(GET_KEYCONTACT, {
  props: ({ data: { KeyContract } }) => ({
    KeyContract: KeyContract ? KeyContract.KeyContract : "PARTNERSHIP"
  })
})(ListeUsers);
