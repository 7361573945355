import React from "react";
import PropTypes from "prop-types";
import {
  FormGroup,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Button
} from "reactstrap";
import SearchIcon from "mdi-react/SearchIcon";
import FilterRemoveIcon from "mdi-react/FilterRemoveIcon";
import {
  statusConst,
  BASE_LANGUAGE_URL,
  LEVELS_WITH_ID
} from "../../../handler/utils/constants";
import SelectTwo from "../../../shared/components/SelectTwo";
import SelecLanguageTwo from "../../../shared/components/SelecLanguageTwo";

class HeaderListesUsers extends React.Component {
  static propTypes = {
    getStatus: PropTypes.func,
    onChangeSearch: PropTypes.func,
    getSort: PropTypes.func,
    getFilterLanguage: PropTypes.func,
    getLearnedLevel: PropTypes.func,
    status: PropTypes.string,
    learnedLevel: PropTypes.number,
    search: PropTypes.string
  };

  static defaultProps = {
    status: "ALL",
    learnedLevel: -1,
    search: ""
  };
  constructor(props) {
    super(props);

    this.state = {
      status: props.status ? props.status : "ALL",
      index: 0,
      search: props.search ? props.search : "",
      learnedLevel: props.learnedLevel ? props.learnedLevel : -1,
      languages: [],
      filterLanguage: null
    };
  }
  componentDidMount() {
    fetch(`${BASE_LANGUAGE_URL}/languages`).then(res =>
      res.json().then(data => this.setState({ languages: data.languages }))
    );
  }
  onChange = e => {
    if (e.target) this.props.getStatus(e.target.id);
  };
  onChangeSearch = e => {
    this.setState({ search: e.target.value });
    this.props.onChangeSearch(e.target.value);
  };

  onSelectStatus = (value, name) => {
    this.setState({ [name]: value });
    this.props.getStatus(value);
  };

  onSelectLearnedLevel = (value, name) => {
    this.setState({ [name]: value });
    this.props.getLearnedLevel(value);
  };
  onSelectLanguage = (value, name) => {
    this.setState({ [name]: value });
    this.props.getFilterLanguage(value);
  };
  onClear = () => {
    this.setState({
      status: "ALL",
      search: "",
      learnedLevel: -1,
      filterLanguage: null,
      index: this.state.index + 1
    });
    this.props.getFilterLanguage(null);
    this.props.getLearnedLevel(-1);
    this.props.getStatus("ALL");
    this.props.onChangeSearch(null);
  };
  render() {
    const {
      status,
      search,
      learnedLevel,
      filterLanguage,
      index,
      languages
    } = this.state;
    return (
      <div>
        <FormGroup tag="fieldset">
          {(status !== "ALL" ||
            search !== "" ||
            learnedLevel !== -1 ||
            filterLanguage !== null) && (
            <Row className="Filter">
              <Button className="Filter-btn" onClick={this.onClear}>
                <FilterRemoveIcon className="Filter-svg" />
              </Button>
            </Row>
          )}
          <Row>
            <Col
              sm={3}
              style={{
                display: "flex",
                justifyContent: "space-between",

                padding: 8
              }}>
              <InputGroup>
                <InputGroupAddon
                  addonType="prepend"
                  style={{
                    backgroundColor: "#e8e8e8",
                    width: "3em",
                    borderRadius: "24px 0px 0px 24px",
                    border: "1px solid #ced4da"
                  }}>
                  <SearchIcon
                    style={{
                      margin: "auto",
                      borderRadius: "24px 0px 0px 24px",
                      color: "#6b6b6b",
                      fontSize: "1.3em"
                    }}
                  />
                </InputGroupAddon>
                <Input
                  className="Profil-group__input"
                  placeholder="Rechercher..."
                  type="text"
                  value={search}
                  onChange={this.onChangeSearch}
                />
              </InputGroup>
            </Col>

            <Col
              sm={3}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 8
              }}>
              <SelecLanguageTwo
                className="Profil-group__input"
                onSelectLanguage={this.onSelectLanguage}
                optionLabel={"Langue apprise"}
                defaultValue={filterLanguage}
                index={index}
                name={"filterLanguage"}
                items={[{ id: "ALL", name: "all" }, ...languages]}
              />
            </Col>
            <Col
              sm={3}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 8
              }}>
              <SelectTwo
                className="Profil-group__input"
                onSelect={this.onSelectLearnedLevel}
                defaultValue={learnedLevel}
                optionLabelDisabled={true}
                index={index}
                optionLabel={"Niveau langue apprise"}
                name={"learnedLevel"}
                items={[
                  {
                    id: -1,
                    label: "Tous les niveaux"
                  },
                  ...LEVELS_WITH_ID
                ]}
              />
            </Col>

            <Col
              sm={3}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 8
              }}>
              <SelectTwo
                className="Profil-group__input"
                onSelect={this.onSelectStatus}
                name={"status"}
                index={index}
                optionLabel={"Etat des utlisateurs"}
                defaultValue={status}
                items={statusConst}
              />
            </Col>
          </Row>
        </FormGroup>
      </div>
    );
  }
}

export default HeaderListesUsers;
