import React from "react";
import Switch from "react-switch";
import PropTypes from "prop-types";

const SwitchTootgle = ({ isOn, handleToggle, disabled }) => {
  return (
    <Switch
      disabled={disabled}
      checked={isOn}
      onColor="#17cde8"
      offColor="#d3d3d3"
      width={50}
      height={25}
      onChange={handleToggle}
    />
  );
};

SwitchTootgle.propTypes = {
  disabled: PropTypes.bool,
  isOn: PropTypes.bool,
  handleToggle: PropTypes.func
};
export default SwitchTootgle;
