import React from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";
import ListeUsers from "./components/ListeUsers";
import { withRouter } from "react-router-dom";
class MyUsersRequests extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object
  };

  static defaultProps = {
    loading: false,
    error: null
  };
  render() {
    return (
      <Container className="dashboard">
        <ListeUsers />
      </Container>
    );
  }
}
export default withRouter(MyUsersRequests);
