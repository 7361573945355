import React from "react";
import { Col, Container, Row, Button } from "reactstrap";
import UserInfosTop from "./components/UserInfosTop";
import GraphQlResult from "../../shared/components/GraphQLResult";
import PropTypes from "prop-types";
import { GET_USER_BY_ID } from "../../handler/queries";
import InfosCard from "./components/InfosCard";
import UserEditIcon from "mdi-react/UserEditIcon";
import moment from "moment";
import { graphql, compose } from "react-apollo";
import { GET_KEYCONTACT } from "../../handler/queries.local";
import gql from "graphql-tag";
import {
  languagesConst,
  SOCIABILITY,
  LEVELS_WITH_ID
} from "../../handler/utils/constants";
import { withRouter } from "react-router";
const img1 = `${process.env.PUBLIC_URL}/img/images/temps-conv.png`;
const img2 = `${process.env.PUBLIC_URL}/img/images/tmps-moy.png`;
const img3 = `${process.env.PUBLIC_URL}/img/images/total-heure.png`;
const img4 = `${process.env.PUBLIC_URL}/img/images/graph.png`;
class User extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.object,
    user: PropTypes.object,
    userConversationCount: PropTypes.number,
    userConversationTime: PropTypes.number,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    refetch: PropTypes.func,
    KeyContract: PropTypes.string
  };

  static defaultProps = {
    userConversationCount: 0,
    userConversationTime: 0,
    loading: false,
    error: null,
    user: null
  };
  render() {
    const {
      loading,
      error,
      user,
      refetch,
      userConversationCount,
      userConversationTime,
      KeyContract
    } = this.props;

    return (
      <Container>
        <GraphQlResult
          error={error}
          loading={loading}
          emptyResult={!loading && !user}>
          {user && (
            <div className="profile">
              <Row>
                <Col xs={12} md={6} lg={4} xl={4}>
                  <UserInfosTop user={user} refetch={refetch} />
                </Col>
                <Col xs={12} md={6} lg={4} xl={4}>
                  <Container className="dashboard">
                    <InfosCard
                      icon={
                        user &&
                        user.learnedLanguage &&
                        user.learnedLanguage.chatFlag
                      }
                      isImage={true}
                      value={
                        user &&
                        user.learnedLanguage &&
                        user.learnedLanguage.name
                          ? languagesConst.filter(
                              exp => exp.value === user.learnedLanguage.name
                            )[0].label
                          : "--"
                      }
                      label="Langue native"
                      iconClassName="fill-danger"
                      valueClassName="text-danger"
                    />
                    <InfosCard
                      icon={
                        user &&
                        user.spokenLanguage &&
                        user.spokenLanguage.chatFlag
                      }
                      isImage={true}
                      value={
                        user && user.spokenLanguage && user.spokenLanguage.name
                          ? languagesConst.filter(
                              exp => exp.value === user.spokenLanguage.name
                            )[0].label
                          : "--"
                      }
                      label="Langue apprise"
                      iconClassName="fill-danger"
                      valueClassName="text-danger"
                    />

                    <InfosCard
                      isImage={true}
                      icon={img4}
                      value={
                        user &&
                        user.rating &&
                        user.rating.learnedLevel &&
                        user.rating.learnedLevelRatingTimes &&
                        user.rating.learnedLevelRatingTimes !== 0
                          ? Math.trunc(
                              user.rating.learnedLevel /
                                user.rating.learnedLevelRatingTimes
                            ) < 6 &&
                            Math.trunc(
                              user.rating.learnedLevel /
                                user.rating.learnedLevelRatingTimes
                            ) > 0
                            ? LEVELS_WITH_ID.filter(
                                exp =>
                                  exp.id ===
                                  Math.trunc(
                                    user.rating.learnedLevel /
                                      user.rating.learnedLevelRatingTimes
                                  )
                              ) &&
                              LEVELS_WITH_ID.filter(
                                exp =>
                                  exp.id ===
                                  Math.trunc(
                                    user.rating.learnedLevel /
                                      user.rating.learnedLevelRatingTimes
                                  )
                              ).length > 0
                              ? LEVELS_WITH_ID.filter(
                                  exp =>
                                    exp.id ===
                                    Math.trunc(
                                      user.rating.learnedLevel /
                                        user.rating.learnedLevelRatingTimes
                                    )
                                )[0].label
                              : LEVELS_WITH_ID.filter(
                                  exp =>
                                    exp.id ===
                                    Math.trunc(
                                      user.rating.learnedLevel /
                                        user.rating.learnedLevelRatingTimes
                                    )
                                )[0].label
                            : LEVELS_WITH_ID.filter(
                                exp => exp.id === user.rating.learnedLevel
                              )[0].label
                          : LEVELS_WITH_ID[0].label
                      }
                      label="Niveau en langue"
                      iconClassName="fill-danger"
                      valueClassName="text-danger"
                    />
                    <InfosCard
                      icon="offers-active"
                      isRating={true}
                      rating={user.rating.sociabilityLevel}
                      value={
                        user &&
                        user.rating &&
                        user.rating.learnedLevelRatingTimes &&
                        user.rating.learnedLevelRatingTimes !== 0
                          ? SOCIABILITY[
                              Math.trunc(
                                user.rating.sociabilityLevel /
                                  user.rating.learnedLevelRatingTimes
                              )
                            ]
                          : SOCIABILITY[0]
                      }
                      label="Socialibilité"
                      iconClassName="fill-danger"
                      valueClassName="text-danger"
                    />
                  </Container>
                </Col>
                <Col xs={12} md={6} lg={4} xl={4}>
                  <Container className="dashboard">
                    <InfosCard
                      value={
                        user.lastLoggin
                          ? moment(user.lastLoggin).format("DD/MM/YYYY")
                          : "N/A"
                      }
                      isImage={true}
                      icon={img1}
                      label="Dernière connexion"
                      iconClassName="fill-danger"
                      valueClassName="text-danger"
                    />
                    <InfosCard
                      isImage={true}
                      icon={img1}
                      value={moment
                        .utc(userConversationTime * 1000)
                        .format("HH:mm:ss")}
                      label="Temps total en conversation"
                      iconClassName="fill-danger"
                      valueClassName="text-danger"
                    />
                    <InfosCard
                      isImage={true}
                      icon={img3}
                      value={userConversationCount}
                      label="Nombre de conversations"
                      iconClassName="fill-danger"
                      valueClassName="text-danger"
                    />
                    <InfosCard
                      isImage={true}
                      icon={img2}
                      value={moment(
                        userConversationCount === 0
                          ? 0
                          : (userConversationTime / userConversationCount) *
                              1000
                      )
                        .utc()
                        .format("HH:mm:ss")}
                      label="Temps moyen des conversations"
                      iconClassName="fill-danger"
                      valueClassName="text-danger"
                    />
                  </Container>
                </Col>
              </Row>
            </div>
          )}
          {KeyContract !== "PARTNERSHIP" && (
            <div className="Button-add__div" title="Ajouter un utilisateur">
              <Button
                className="Button-add__btn"
                onClick={() =>
                  this.props.history.push(`/modifier-utilisateur/${user.id}`)
                }>
                <UserEditIcon className="Button-add__btn-icon" />
              </Button>
            </div>
          )}
        </GraphQlResult>
      </Container>
    );
  }
}

const GET_USER_CONVERSATION_NUMBER = gql`
  query userConversationCount($userId: ID!) {
    userConversationCount(userId: $userId)
  }
`;
const GET_USER_CONVERSATION_TIME = gql`
  query userConversationTime($userId: ID!) {
    userConversationTime(userId: $userId)
  }
`;
export default withRouter(
  compose(
    graphql(GET_USER_BY_ID, {
      options: props => ({
        variables: { userId: props.match.params.id },
        fetchPolicy: "network-only"
      }),
      props: ({ data: { loading, error, user, refetch } }) => ({
        loading,
        error,
        user,
        refetch
      })
    }),
    graphql(GET_USER_CONVERSATION_NUMBER, {
      options: props => ({
        variables: { userId: props.match.params.id },
        fetchPolicy: "network-only"
      }),
      props: ({ data: { userConversationCount } }) => ({
        userConversationCount
      })
    }),
    graphql(GET_USER_CONVERSATION_TIME, {
      options: props => ({
        variables: { userId: props.match.params.id },
        fetchPolicy: "network-only"
      }),
      props: ({ data: { userConversationTime } }) => ({
        userConversationTime
      })
    }),
    graphql(GET_KEYCONTACT, {
      props: ({ data: { KeyContract } }) => ({
        KeyContract: KeyContract ? KeyContract.KeyContract : "PARTNERSHIP"
      })
    })
  )(User)
);
