import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import { languagesConst } from "../../handler/utils/constants";

class SelecLanguagStat extends React.Component {
  static propTypes = {
    onSelectLanguageStat: PropTypes.func,
    className: PropTypes.string,
    items: PropTypes.array,
    defaultValue: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.string,
    optionLabel: PropTypes.string,
    optionLabelDisabled: PropTypes.bool
  };

  static defaultProps = {
    className: "",
    checked: false,
    error: "",
    name: "",
    optionLabel: "Aucun(e)",
    optionLabelDisabled: true
  };

  constructor(props) {
    super(props);
    this.state = {
      items: props.items,
      value: props.defaultValue
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.items !== this.props.items) {
      this.setState({ items: nextProps.items });
    }
  }

  handleChange = e => {
    if (e.target.value && e.target.value !== "all") {
      this.props.onSelectLanguageStat(
        e.target.value,
        this.state.items.filter(exp => exp.language === e.target.value)[0].stats
      );
      this.setState({ value: e.target.value });
    } else {
      let res = [0, 0, 0, 0, 0];
      this.state.items.map(async item => {
        res[0] = item.stats[0] + res[0];
        res[1] = item.stats[1] + res[1];
        res[2] = item.stats[2] + res[2];
        res[3] = item.stats[3] + res[3];
        res[4] = item.stats[4] + res[4];
      });
      this.props.onSelectLanguageStat(e.target.value, res);
      this.setState({ value: e.target.value });
    }
  };

  render() {
    const { value, items } = this.state;
    const { className, name, error } = this.props;
    return (
      <Input
        className={className}
        style={{ width: "auto", float: "right", width: 170 }}
        onChange={e => this.handleChange(e)}
        value={value}
        type="select"
        name={name}
        invalid={!!error}>
        <option
          value={"all"}
          key={Math.random()}
          style={{ backgroundColor: "#ececec", height: 40 }}>
          Toutes les langues
        </option>
        {items &&
          items.map(item => {
            if (item.learned)
              return (
                <option
                  value={item.language}
                  key={item.id + "" + Math.random()}>
                  {
                    languagesConst.filter(exp => exp.value === item.language)[0]
                      .label
                  }
                </option>
              );
          })}
      </Input>
    );
  }
}

export default SelecLanguagStat;
