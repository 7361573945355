import React from "react";
import { Route, Switch } from "react-router-dom";

import MainWrapper from "./MainWrapper";
import PrivateRoute from "./PrivateRoute";
import FallbackSpinner from "../../shared/components/FallbackSpinner";

import LogIn from "../login/index";
import DefaultDashboard from "../home/index";

import EntrepriseDetails from "../profile/index";
import ChangePassword from "../change-password/index";
import Settings from "../settings/index";
import UserForm from "../user-form/index";
import MyUsers from "../users/index";
import MyUsersRequests from "../users-requests/index";
import User from "../user/index";

import Legal from "../legal/index";
import RGPD from "../rgpd/index";

const privateRoutes = [
  {
    path: ["/", "/accueil"],
    component: DefaultDashboard,
    exact: true
  },

  {
    path: "/compte",
    component: EntrepriseDetails,
    exact: true
  },
  {
    path: ["/parametres/:id", "/parametres"],
    component: Settings,
    exact: true
  },
  {
    path: "/changer-mot-de-passe",
    component: ChangePassword,
    exact: true
  },
  {
    path: "/mes-utilisateurs",
    component: MyUsers,
    exact: true
  },
  {
    path: "/mes-demandes",
    component: MyUsersRequests,
    exact: true
  },

  {
    path: "/ajout-utilisateur",
    component: UserForm,
    exact: true
  },
  {
    path: "/modifier-utilisateur/:id",
    component: UserForm,
    exact: true
  },
  {
    path: "/utilisateur/:id",
    component: User,
    exact: true
  },

  {
    path: "/rgpd",
    component: RGPD,
    exact: true
  },
  {
    path: "/mention-legale",
    component: Legal,
    exact: true
  }
];
const publicRoutes = [
  {
    path: ["/connexion/:token", "/connexion"],
    component: LogIn
  }
  /*  {
    path: "/mot-passe-oublie",
    component: ForgotPassword
  } */
];

const Router = () => (
  <React.Suspense fallback={<FallbackSpinner />}>
    <MainWrapper>
      <main>
        <Switch>
          {publicRoutes.map(route => (
            <Route key={route.path} {...route} />
          ))}
          <PrivateRoute>
            <Switch>
              {privateRoutes.map(route => (
                <Route key={route.path} {...route} />
              ))}
            </Switch>
          </PrivateRoute>
        </Switch>
      </main>
    </MainWrapper>
  </React.Suspense>
);

export default Router;
