import React from "react";
import { Container, Row, Label, Col } from "reactstrap";
import PropTypes from "prop-types";
import StatisticsCard from "./components/StatisticsCard";
import StatisticsPie from "./components/StatisticsPie";
import SelecLanguagStat from "../../shared/components/SelecLanguagStat";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import moment from "moment";
class DefaultDashboard extends React.Component {
  static propTypes = {
    usersStat: PropTypes.array,
    languagesStat: PropTypes.array
  };

  static defaultProps = {
    usersStat: [],
    languagesStat: []
  };
  constructor(props) {
    super(props);
    this.state = {
      language: "all",
      stats: [0, 0, 0, 0, 0]
    };
  }

  UNSAFE_componentWillReceiveProps(nextPros) {
    if (nextPros.languagesStat) {
      let res = [0, 0, 0, 0, 0];
      nextPros.languagesStat.map(async item => {
        if (item.learned) {
          res[0] = item.stats[0] + res[0];
          res[1] = item.stats[1] + res[1];
          res[2] = item.stats[2] + res[2];
          res[3] = item.stats[3] + res[3];
          res[4] = item.stats[4] + res[4];
        }
      });
      this.setState({ language: "all", stats: res });
    }
  }
  onSelectLanguageStat = (value, stats) => {
    this.setState({ language: value, stats });
  };
  render() {
    const { usersStat, languagesStat } = this.props;
    const { language, stats } = this.state;

    return (
      <Container className="dashboard">
        <Row>
          <Col xs={12} md={12} lg={4} xl={4}>
            <StatisticsCard
              icon="team"
              value={usersStat[0]}
              label="Total inscrits"
              iconContainerClassName="border-files"
              iconClassName="fill-greensea"
              valueClassName="text-files"
            />
            <StatisticsCard
              icon="users"
              value={usersStat[1]}
              label="Total actifs"
              iconContainerClassName="border-danger"
              iconClassName="fill-orange"
              valueClassName="text-danger"
            />
          </Col>
          <Col xs={12} md={12} lg={4} xl={4} className="PieChart">
            <Label
              className="statistics-card__label"
              style={{
                textTransform: "uppercase",
                marginBottom: 20,
                marginTop: 10
              }}>
              LANGUE(S) & NIVEAUX
            </Label>
            <SelecLanguagStat
              className="Profil-group__input"
              onSelectLanguageStat={this.onSelectLanguageStat}
              optionLabel={"Langue apprise"}
              name={"filterLanguage"}
              items={languagesStat}
            />
            <StatisticsPie language={language} stats={stats} />
          </Col>
          <Col xs={12} md={12} lg={4} xl={4}>
            <StatisticsCard
              icon="conversations"
              value={usersStat[4]}
              label="Nb conversations"
              iconContainerClassName="border-danger"
              iconClassName="fill-carrot"
              valueClassName="text-danger"
            />
            <StatisticsCard
              icon="conv-time"
              value={
                usersStat && usersStat[3]
                  ? moment(usersStat[3] * 1000)
                      .utc()
                      .format("HH:mm:ss")
                      .split(":")[0] +
                    "h " +
                    moment(usersStat[3] * 1000)
                      .utc()
                      .format("HH:mm:ss")
                      .split(":")[1] +
                    "min " +
                    moment(usersStat[3] * 1000)
                      .utc()
                      .format("HH:mm:ss")
                      .split(":")[2] +
                    "sec"
                  : "00h 00min 00sec"
              }
              label="DURÉE TOTALE DES CONVERSATIONS"
              iconContainerClassName="border-warning"
              iconClassName="fill-belize"
              valueClassName="text-warning"
            />
          </Col>
          {/* <StatisticsCard
            icon="nb-demandes"
            value={usersStat[2]}
            label="Nb demandes"
            iconContainerClassName="border-warning"
            iconClassName="fill-emerland"
            valueClassName="text-warning"
          /> */}
        </Row>
        <Row>
          {/*  <StatisticsCard
            icon="evaluations"
            value={usersStat[5]}
            label="Nb d'évaluations réalisées"
            iconContainerClassName="border-warning"
            iconClassName="fill-pom"
            valueClassName="text-warning"
          /> */}
        </Row>
      </Container>
    );
  }
}

const GET_STATS = gql`
  query usersStat {
    usersStat
  }
`;
const GET_LANGUAGES_STATS = gql`
  query languagesStat {
    languagesStat {
      language
      learned
      stats
    }
  }
`;
export default compose(
  graphql(GET_STATS, {
    options: () => ({
      fetchPolicy: "network-only"
    }),
    props: ({ data: { loading, error, usersStat, refetch } }) => ({
      loading,
      error,
      usersStat,
      refetch
    })
  }),
  graphql(GET_LANGUAGES_STATS, {
    options: () => ({
      fetchPolicy: "network-only"
    }),
    props: ({ data: { loading, error, languagesStat, refetch } }) => ({
      loading,
      error,
      languagesStat,
      refetch
    })
  })
)(DefaultDashboard);
