import React from "react";
import PropTypes from "prop-types";
import { CardBody, ListGroup, Row, Col } from "reactstrap";
import ItemDetails from "../../../shared/components/ItemDetails";

const Ava =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQcz6CIVo0fLrlm9ssxHtKF4JEuLJBxlZXtIBePwbN3NTkkEVEp";

class EntrepriseInfo extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
  };

  static defaultProps = {
    className: "Profil",
    title: "",
    user: {}
  };

  render() {
    const {
      user: {
        profile: { name, nameOfResponsible, photoUrl, address },
        email,
        contract,
        callPref,
        maxActifUserNumber
      }
    } = this.props;
    return (
      <CardBody>
        <div className="Profil-logo">
          <img
            className="Profil-logo__img"
            src={photoUrl ? photoUrl : Ava}
            alt={"logo"}
          />
        </div>
        <ListGroup tag="div" className="Profil-group">
          <Row style={{ marginTop: 30 }}>
            <Col xs={12} md={12} lg={6} xl={6} style={{ marginBottom: 6 }}>
              <ItemDetails label="Nom de l'établissement" value={name} />
            </Col>
            <Col xs={12} md={12} lg={6} xl={6} style={{ marginBottom: 6 }}>
              <ItemDetails label="Email principal" value={email} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={6} xl={6} style={{ marginBottom: 6 }}>
              <ItemDetails label="Adresse" value={address} />
            </Col>
            <Col xs={12} md={6} lg={3} xl={3} style={{ marginBottom: 6 }}>
              <ItemDetails
                label="Type de contrat"
                value={contract === "FLATRATE" ? "Forfait" : "partenariat"}
              />
            </Col>
            <Col xs={12} md={6} lg={3} xl={3} style={{ marginBottom: 6 }}>
              <ItemDetails
                label="Visibilité"
                value={callPref === "PRIVED" ? "Privée" : "Publique"}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12} lg={6} xl={6} style={{ marginBottom: 6 }}>
              <ItemDetails
                label="Nom, prénom du responsable"
                value={nameOfResponsible}
              />
            </Col>
            {contract === "FLATRATE" && (
              <Col xs={12} md={12} lg={6} xl={6} style={{ marginBottom: 6 }}>
                <ItemDetails
                  label="Nombre maximal d'utilisateurs actifs"
                  value={
                    contract === "FLATRATE" ? maxActifUserNumber : "Non définie"
                  }
                />
              </Col>
            )}
          </Row>
        </ListGroup>
      </CardBody>
    );
  }
}

export default EntrepriseInfo;
