import React from "react";
import { Container, Row } from "reactstrap";
import AddUpdateForm from "./components/AddUpdateForm";
import GraphQlResult from "../../shared/components/GraphQLResult";
import PropTypes from "prop-types";
import { GET_USER_BY_ID } from "../../handler/queries";

import { graphql } from "react-apollo";
class UserForm extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.object,
    user: PropTypes.object,
    refetch: PropTypes.func
  };

  static defaultProps = {
    loading: false,
    error: null,
    user: null
  };
  render() {
    const { loading, error, user, refetch } = this.props;

    return (
      <GraphQlResult error={error} loading={loading} emptyResult={loading}>
        {user ? (
          <Container className="dashboard">
            <Row>
              <AddUpdateForm type={"update"} user={user} refetch={refetch} />
            </Row>
          </Container>
        ) : (
          <Container className="dashboard">
            <Row>
              <AddUpdateForm type={"add"} />
            </Row>
          </Container>
        )}
      </GraphQlResult>
    );
  }
}

export default graphql(GET_USER_BY_ID, {
  options: props => ({
    variables: { userId: props.match.params.id },
    fetchPolicy: "cache-and-network"
  }),
  props: ({ data: { loading, error, user, refetch } }) => ({
    loading,
    error,
    user,
    refetch
  })
})(UserForm);
