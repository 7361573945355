import React from "react";
import PropTypes from "prop-types";
import { Container, Label, Row, Col } from "reactstrap";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
import { withRouter, Link } from "react-router-dom";
import { GET_USERS, GET_STATS } from "../../../handler/queries";

import {
  languagesConst,
  LEVELS_WITH_ID
} from "../../../handler/utils/constants";
import Icon from "../../../shared/components/Icon";
import SwitchTootgle from "../../../shared/components/Switch";
import Alert from "../../../handler/utils/Alert";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import moment from "moment";
class UserItem extends React.PureComponent {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    user: PropTypes.object,
    state: PropTypes.string,
    search: PropTypes.string,
    skip: PropTypes.number,
    limit: PropTypes.number,
    changeUserStatus: PropTypes.func,
    removeUser: PropTypes.func,
    refetch: PropTypes.func,
    KeyContract: PropTypes.string
  };

  static defaultProps = {
    user: {},
    status: "ALL",
    sort: "recent",
    skip: 0,
    limit: 10,
    search: "",
    KeyContract: "PARTNERSHIP"
  };

  onStatusChange = () => {
    this.props
      .changeUserStatus({
        variables: {
          userId: this.props.user.id
        },
        refetchQueries: [
          {
            query: GET_USERS,
            variables: {
              state: this.props.state,
              search: this.props.search,
              skip: this.props.skip,
              limit: this.props.limit
            }
          },
          {
            query: GET_STATS
          }
        ]
      })
      .then(rest => {
        this.props.refetch();
        if (
          rest.data &&
          rest.data.changeUserStatus &&
          rest.data.changeUserStatus.success
        )
          Alert.success(
            rest.data &&
              rest.data.changeUserStatus &&
              rest.data.changeUserStatus.message
          );
        else
          Alert.warning(
            rest.data &&
              rest.data.changeUserStatus &&
              rest.data.changeUserStatus.message
          );
      });
  };
  onUserRemove = () => {
    this.props
      .removeUser({
        variables: {
          userId: this.props.user.id
        },
        refetchQueries: [
          {
            query: GET_USERS,
            variables: {
              state: this.props.state,
              search: this.props.search,
              skip: this.props.skip,
              limit: this.props.limit
            }
          },
          {
            query: GET_STATS
          }
        ]
      })
      .then(rest => {
        this.props.refetch();
        if (rest.data && rest.data.removeUser && rest.data.removeUser.success)
          Alert.success(
            rest.data && rest.data.removeUser && rest.data.removeUser.message
          );
        else
          Alert.warning(
            rest.data && rest.data.removeUser && rest.data.removeUser.message
          );
      });
  };
  render() {
    const { user, KeyContract } = this.props;
    return (
      <Container key={user.id} className="OfferItem">
        <Row style={{ marginLeft: 0, marginRight: 0 }}>
          <Col xs={12} md={3} lg={3} xl={3}>
            <div className="bloc_t">
              <Label className="OfferItem-name">
                {user.online && user.status === "ACTIVE" && (
                  <div className="user-online"></div>
                )}
                <Link to={`/utilisateur/${user.id}`}>
                  {user.profile && user.profile.photoUrl ? (
                    <img
                      src={user.profile.photoUrl}
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: 24,
                        marginRight: 10
                      }}
                    />
                  ) : (
                    <img
                      src="https://f0.pngfuel.com/png/312/283/man-face-clip-art-png-clip-art-thumbnail.png"
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: 24,
                        marginRight: 10
                      }}
                    />
                  )}
                  {user.profile.lastName + " " + user.profile.firstName}
                </Link>
              </Label>
            </div>
          </Col>
          <Col
            xs={12}
            md={KeyContract !== "PARTNERSHIP" ? 2 : 3}
            lg={KeyContract !== "PARTNERSHIP" ? 2 : 3}
            xl={KeyContract !== "PARTNERSHIP" ? 2 : 3}
            style={{ display: "flex", alignItems: "center" }}>
            <div className="bloc_t">
              <Label className="OfferItem-name" style={{ marginBottom: 0 }}>
                {user && user.learnedLanguage && user.learnedLanguage.name
                  ? languagesConst.filter(
                      exp => exp.value === user.learnedLanguage.name
                    )[0].label
                  : "--"}
              </Label>

              <span style={{ color: "#36aec5", fontSize: 13 }}>
                (
                {user && user.rating && user.rating.learnedLevel
                  ? LEVELS_WITH_ID.filter(
                      exp => exp.id === user.rating.learnedLevel
                    )[0].label
                  : "--"}
                )
              </span>
            </div>
          </Col>

          <Col
            xs={12}
            md={KeyContract !== "PARTNERSHIP" ? 2 : 3}
            lg={KeyContract !== "PARTNERSHIP" ? 2 : 3}
            xl={KeyContract !== "PARTNERSHIP" ? 2 : 3}
            style={{ display: "flex", alignItems: "center" }}>
            <div className="bloc_t">
              <Label className="OfferItem-name">
                {user.createdAt
                  ? moment(user.createdAt).format("DD/MM/YYYY")
                  : "--"}
              </Label>
            </div>
          </Col>
          <Col
            xs={12}
            md={KeyContract !== "PARTNERSHIP" ? 2 : 3}
            lg={KeyContract !== "PARTNERSHIP" ? 2 : 3}
            xl={KeyContract !== "PARTNERSHIP" ? 2 : 3}
            style={{ display: "flex", alignItems: "center" }}>
            <div className="bloc_t">
              <Label className="OfferItem-name">
                {user.lastLoggin
                  ? moment(user.lastLoggin).format("DD/MM/YYYY")
                  : "--"}
              </Label>
            </div>
          </Col>
          {KeyContract !== "PARTNERSHIP" && (
            <Col
              xs={12}
              md={3}
              lg={3}
              xl={3}
              style={{
                alignItems: "center",
                alignSelf: "center",
                display: "flex",
                flexDirection: "column"
              }}>
              <Row
                style={{
                  display: "flex"
                }}
                className="Row-up rou">
                <SwitchTootgle
                  isOn={user.status === "ACTIVE"}
                  disabled={false}
                  onColor="rgb(46, 83, 152)"
                  handleToggle={() =>
                    confirmAlert({
                      title: "Changement de statut",
                      message: "Êtes-vous sûr de vouloir changer le statut?",
                      buttons: [
                        {
                          label: "Non",
                          onClick: () => {}
                        },
                        {
                          label: "Oui",
                          onClick: () => this.onStatusChange()
                        }
                      ]
                    })
                  }
                />
                <div
                  className="rouR"
                  style={{ cursor: "pointer", marginLeft: 10 }}
                  title="Modifier">
                  <Link to={`/modifier-utilisateur/${user.id}`}>
                    <Icon className="OfferItem__edit-offer" name="edit-offer" />
                  </Link>
                </div>
                <div
                  className="rouR"
                  title="Supprimer"
                  onClick={() =>
                    confirmAlert({
                      title: "Suppression d'un utilisateur",
                      message:
                        "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
                      buttons: [
                        {
                          label: "Non",
                          onClick: () => {}
                        },
                        {
                          label: "Oui",
                          onClick: () => this.onUserRemove()
                        }
                      ]
                    })
                  }
                  style={{ cursor: "pointer" }}>
                  <Icon
                    className="OfferItem__remove-offer"
                    name="remove-offer"
                  />
                </div>
              </Row>
            </Col>
          )}
        </Row>
      </Container>
    );
  }
}
const UPDATE_USER_STATE = gql`
  mutation changeUserStatus($userId: ID!) {
    changeUserStatus(userId: $userId) {
      success
      message
    }
  }
`;
const REMOVE_USER = gql`
  mutation removeUser($userId: ID!) {
    removeUser(userId: $userId) {
      success
      message
    }
  }
`;
export default withRouter(
  compose(
    graphql(UPDATE_USER_STATE, {
      name: "changeUserStatus"
    }),
    graphql(REMOVE_USER, {
      name: "removeUser"
    })
  )(UserItem)
);
